import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import MenuIcon from "@mui/icons-material/Menu";
import { motion } from "framer-motion";
import {
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { Heart, Droplets, Music } from "lucide-react";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import QuizIcon from "@mui/icons-material/Quiz";
import logo from "./logo.png";
import { theme } from "./theme";

// Define your theme

const IconSvg = ({ icon: Icon }) => {
  return <Icon size={48} style={{ color: theme.palette.primary.main }} />;
};

const features = [
  {
    title: "Pregnancy Exercises",
    icon: (props) => (
      <SelfImprovementIcon {...props} sx={{ fontSize: 64, p: 1 }} />
    ),
    description:
      "Safe and effective pregnancy exercises with 3D animated demonstrations to improve flexibility, strengthen muscles for labor, and maintain overall wellness during pregnancy",
  },
  {
    title: "AI Companion for Pregnancy Support",
    icon: Heart,
    description:
      "Your personal AI companion to help understand medical terms, plan pregnancy-safe diets, and get answers to all your pregnancy-related questions",
  },
  {
    title: "Daily water intake tracker",
    icon: Droplets,
    description:
      "Get reminders on how much water you should be drinking and set daily water intake goals",
  },
  {
    title: "Calm and peaceful music for good night sleep",
    icon: Music,
    description: "Manage your sleep by listening to calm and peaceful music",
  },
  {
    title: "AI-Powered Diet Planning",
    icon: (props) => <RestaurantIcon {...props} sx={{ fontSize: 64, p: 1 }} />,
    description:
      "Get personalized pregnancy diet plans created by AI based on your preferences and existing meal patterns",
  },
];

const FeatureCard = ({ title, icon, description }) => (
  <Card
    elevation={2}
    sx={{ height: "100%", display: "flex", flexDirection: "column" }}
  >
    <CardContent
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <IconSvg icon={icon} loading="lazy" />
      <Typography variant="h6" component="h3" sx={{ mt: 2, mb: 1 }}>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </CardContent>
  </Card>
);

const MobileMenu = ({ open, onClose }) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <List sx={{ width: 250 }}>
        <ListItem button component="a" href="#features" onClick={onClose}>
          <ListItemText primary="Features" />
        </ListItem>
        <ListItem button component="a" href="#waitlist" onClick={onClose}>
          <ListItemText primary="Join Waitlist" />
        </ListItem>
        <ListItem button component="a" href="#contact" onClick={onClose}>
          <ListItemText primary="Contact" />
        </ListItem>
        <ListItem button component="a" href="/privacy-policy" onClick={onClose}>
          <ListItemText primary="Privacy Policy" />
        </ListItem>
      </List>
    </Drawer>
  );
};

const LandingPage = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Animation variants
  const taglineVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  const subtitleVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        delay: 0.3,
        ease: "easeOut",
      },
    },
  };

  const buttonVariants = {
    hover: {
      scale: 1.05,
      transition: {
        duration: 0.2,
      },
    },
    tap: {
      scale: 0.95,
    },
  };

  const logoVariants = {
    hover: {
      rotate: [0, -10, 10, -10, 0],
      transition: {
        duration: 0.5,
      },
    },
  };

  const navItemVariants = {
    hover: {
      y: -2,
      transition: {
        duration: 0.2,
      },
    },
  };

  const featureCardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.2 * i,
        duration: 0.5,
      },
    }),
    hover: {
      y: -10,
      transition: {
        duration: 0.2,
      },
    },
  };

  const handleJoinWaitlist = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLScjpoYmQvDC9mdZgi_obFkGD7wd_rqHu0A3YXDV8Bs1wEt-xQ/viewform?usp=sf_link",
      "_blank"
    );
  };

  return (
    <>
      <CssBaseline />
      <header>
        <AppBar
          position="fixed"
          color="transparent"
          elevation={0}
          sx={{
            backdropFilter: "blur(2px)",
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Toolbar>
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <motion.div whileHover="hover" variants={logoVariants}>
                <Box
                  component="a"
                  href="#"
                  onClick={() => window.scrollTo(0, 0)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ color: "primary.main", fontWeight: "bold" }}
                  >
                    Goodnewzz
                  </Typography>
                  <Box
                    component="img"
                    src={logo}
                    alt="Goodnewzz Logo"
                    sx={{
                      height: 32,
                      width: 32,
                      marginLeft: 1,
                      objectFit: "contain",
                    }}
                    loading="lazy"
                  />
                </Box>
              </motion.div>
            </Box>
            {isMobile ? (
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={() => setMobileMenuOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <>
                {["Features", "Join Waitlist", "Contact"].map((item, index) => (
                  <motion.div
                    key={item}
                    variants={navItemVariants}
                    whileHover="hover"
                  >
                    <Button
                      color="inherit"
                      href={`#${item.toLowerCase().replace(" ", "-")}`}
                    >
                      {item}
                    </Button>
                  </motion.div>
                ))}
              </>
            )}
          </Toolbar>
        </AppBar>
        <MobileMenu
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
        />
      </header>

      <main>
        <article>
          <section aria-label="Hero Section">
            <Container
              maxWidth="md"
              sx={{ mt: 16, mb: 8, textAlign: "center" }}
            >
              <motion.div
                initial="hidden"
                animate="visible"
                variants={taglineVariants}
              >
                <Typography
                  variant="h2"
                  component="h1"
                  gutterBottom
                  sx={{
                    background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.tertiary.main} 90%)`,
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: "bold",
                  }}
                >
                  Your Ultimate Pregnancy Companion
                </Typography>
              </motion.div>
              <motion.div
                initial="hidden"
                animate="visible"
                variants={subtitleVariants}
              >
                <Typography
                  variant="h5"
                  component="p"
                  color="text.secondary"
                  paragraph
                >
                  Empowering expectant mothers with AI-powered insights and
                  personalized care
                </Typography>
              </motion.div>
              <Grid container marginTop={2} spacing={2} justifyContent="center">
                <Grid item>
                  <motion.div
                    whileHover="hover"
                    whileTap="tap"
                    variants={buttonVariants}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<AndroidIcon />}
                      href="https://play.google.com/store/apps/details?id=com.shubhamdeol.goodnewzz"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        borderRadius: 4,
                        px: 4,
                        py: 1.5,
                        textTransform: "none",
                        fontSize: "1rem",
                      }}
                    >
                      Download on Android
                    </Button>
                  </motion.div>
                </Grid>
                <Grid item>
                  <motion.div
                    whileHover="hover"
                    whileTap="tap"
                    variants={buttonVariants}
                  >
                    <Button
                      variant="outlined"
                      size="large"
                      startIcon={<AppleIcon />}
                      onClick={handleJoinWaitlist}
                      sx={{
                        bgcolor: "#1D1B20",
                        borderColor: "#1D1B20",
                        color: "white",
                        "&:hover": {
                          bgcolor: "#2D2B30",
                          borderColor: "#2D2B30",
                        },
                        borderRadius: 4,
                        px: 4,
                        py: 1.5,
                        textTransform: "none",
                        fontSize: "1rem",
                      }}
                    >
                      Join iOS Waitlist
                    </Button>
                  </motion.div>
                </Grid>
              </Grid>
            </Container>

            <Container id="features" maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={taglineVariants}
              >
                <Typography
                  variant="h3"
                  component="h2"
                  align="center"
                  gutterBottom
                >
                  Features
                </Typography>
              </motion.div>
              <Grid container spacing={4}>
                {features.map((feature, index) => (
                  <Grid item key={index} xs={12} sm={6} md={3}>
                    <motion.div
                      custom={index}
                      initial="hidden"
                      whileInView="visible"
                      whileHover="hover"
                      viewport={{ once: true, amount: 0.2 }}
                      variants={featureCardVariants}
                    >
                      <FeatureCard {...feature} />
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Container>

            <Container
              maxWidth="lg"
              sx={{
                mt: 8,
                mb: 8,
                bgcolor: "primary.light",
                py: 8,
                borderRadius: theme.shape.borderRadius,
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <Typography
                  variant="h3"
                  component="h2"
                  align="center"
                  gutterBottom
                >
                  Curated Soundtracks for Pregnancy
                </Typography>
                <Typography
                  variant="h6"
                  align="center"
                  color="text.secondary"
                  paragraph
                >
                  Discover our specially designed audio tracks to support your
                  pregnancy journey
                </Typography>
              </motion.div>
              <Grid container spacing={4}>
                {[
                  {
                    title: "Relaxation Melodies",
                    description:
                      "Soothing sounds to help you unwind and de-stress",
                    color: theme.palette.primary.main,
                  },
                  {
                    title: "Sleep Soundscapes",
                    description:
                      "Gentle audio to guide you into a restful sleep",
                    color: theme.palette.secondary.main,
                  },
                  {
                    title: "Hypnobirthing Tracks",
                    description:
                      "Specialized audio for a calm and confident birthing experience",
                    color: theme.palette.primary.main,
                  },
                ].map((track, index) => (
                  <Grid item xs={12} md={4} key={track.title}>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.6, delay: index * 0.2 }}
                      whileHover={{ scale: 1.05 }}
                    >
                      <Card>
                        <CardContent>
                          <Music
                            size={48}
                            style={{
                              color: track.color,
                              margin: "0 auto",
                              display: "block",
                            }}
                          />
                          <Typography
                            variant="h6"
                            component="h3"
                            align="center"
                            sx={{ mt: 2 }}
                          >
                            {track.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            align="center"
                          >
                            {track.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Container>

            <Container
              maxWidth="lg"
              sx={{
                mt: 12,
                mb: 8,
                bgcolor: "background.paper",
                borderRadius: theme.shape.borderRadius,
                boxShadow: 1,
                overflow: "hidden",
              }}
            >
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6} sx={{ p: 4 }}>
                  <motion.div
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                  >
                    <Typography variant="h3" component="h2" gutterBottom>
                      Interactive Exercise Guidance
                    </Typography>
                    <Typography variant="h6" color="text.secondary" paragraph>
                      Stay active safely during your pregnancy journey
                    </Typography>
                    <Box sx={{ mb: 3 }}>
                      {[
                        "Improve flexibility and strength for easier labor",
                        "Reduce pregnancy discomfort and back pain",
                        "Boost energy levels and mood",
                        "Maintain healthy weight gain",
                        "Enhanced posture and balance",
                      ].map((benefit, index) => (
                        <motion.div
                          key={index}
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          viewport={{ once: true }}
                          transition={{ duration: 0.4, delay: index * 0.1 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1,
                              color: "text.secondary",
                            }}
                          >
                            <Box
                              component="span"
                              sx={{
                                width: 6,
                                height: 6,
                                borderRadius: "50%",
                                bgcolor: "primary.main",
                                mr: 2,
                              }}
                            />
                            <Typography variant="body1">{benefit}</Typography>
                          </Box>
                        </motion.div>
                      ))}
                    </Box>
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <Button
                        variant="contained"
                        color="tertiary"
                        size="large"
                        href="https://play.google.com/store/apps/details?id=com.shubhamdeol.goodnewzz"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          mt: 2,
                          borderRadius: 4,
                          px: 4,
                          py: 1.5,
                          textTransform: "none",
                          fontSize: "1rem",
                        }}
                      >
                        Download on Android
                      </Button>
                    </motion.div>
                  </motion.div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    position: "relative",
                    minHeight: { xs: 300, md: 400 },
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        bgcolor: "primary.light",
                        borderRadius: theme.shape.borderRadius,
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src="/exercise-model-placeholder.png"
                        alt="3D Pregnancy Exercise Model"
                        sx={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                        }}
                        loading="lazy"
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          position: "absolute",
                          bottom: 8,
                          right: 8,
                          bgcolor: "rgba(255, 255, 255, 0.8)",
                          px: 1,
                          borderRadius: 1,
                        }}
                      >
                        3D Model Preview
                      </Typography>
                    </Box>
                  </motion.div>
                </Grid>
              </Grid>
            </Container>

            <Container
              maxWidth="lg"
              sx={{
                mt: 8,
                mb: 8,
                bgcolor: "background.paper",
                borderRadius: theme.shape.borderRadius,
                boxShadow: 1,
                overflow: "hidden",
              }}
            >
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6} sx={{ p: 4 }}>
                  <motion.div
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                  >
                    <Typography variant="h3" component="h2" gutterBottom>
                      Smart Diet Planning with AI
                    </Typography>
                    <Typography variant="h6" color="text.secondary" paragraph>
                      Personalized nutrition guidance for a healthy pregnancy
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                      {[
                        {
                          icon: UploadFileIcon,
                          title: "Upload Existing Diet Plan",
                          description:
                            "Upload your current diet plan and let our AI analyze and optimize it for your pregnancy journey",
                        },
                        {
                          icon: QuizIcon,
                          title: "Answer Simple Questions",
                          description:
                            "Answer a few questions about your preferences and let our AI create a personalized diet plan tailored just for you",
                        },
                      ].map((item, index) => (
                        <motion.div
                          key={index}
                          initial={{ opacity: 0, y: 20 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          viewport={{ once: true }}
                          transition={{ duration: 0.4, delay: index * 0.2 }}
                          whileHover={{ scale: 1.02 }}
                        >
                          <Card sx={{ mb: 3 }}>
                            <CardContent>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  mb: 2,
                                }}
                              >
                                <item.icon
                                  sx={{
                                    fontSize: 40,
                                    color: "secondary.main",
                                    mr: 2,
                                  }}
                                />
                                <Box>
                                  <Typography variant="h6" gutterBottom>
                                    {item.title}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {item.description}
                                  </Typography>
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        </motion.div>
                      ))}
                    </Box>

                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={<RestaurantIcon />}
                        href="https://play.google.com/store/apps/details?id=com.shubhamdeol.goodnewzz"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          borderRadius: 4,
                          px: 4,
                          py: 1.5,
                          textTransform: "none",
                          fontSize: "1rem",
                        }}
                      >
                        Try Diet Planning
                      </Button>
                    </motion.div>
                  </motion.div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        height: "100%",
                        minHeight: 400,
                        bgcolor: "primary.light",
                        borderRadius: theme.shape.borderRadius,
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: 4,
                      }}
                    >
                      <Box
                        component="img"
                        src="/dietPreview.png"
                        alt="AI Diet Planning Preview"
                        sx={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                          borderRadius: theme.shape.borderRadius,
                          boxShadow: 2,
                        }}
                        loading="lazy"
                      />
                    </Box>
                  </motion.div>
                </Grid>
              </Grid>
            </Container>

            <Box id="waitlist" sx={{ bgcolor: "primary.light", py: 8 }}>
              <Container maxWidth="md" sx={{ textAlign: "center" }}>
                <Typography variant="h3" component="h2" gutterBottom>
                  Available Now on Android
                </Typography>
                <Typography variant="h6" color="text.secondary" paragraph>
                  Download Goodnewzz on Android today and transform your
                  pregnancy journey! iOS version coming soon.
                </Typography>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="success"
                      size="large"
                      startIcon={<AndroidIcon />}
                      href="https://play.google.com/store/apps/details?id=com.shubhamdeol.goodnewzz"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        borderRadius: 4,
                        px: 4,
                        py: 1.5,
                        textTransform: "none",
                        fontSize: "1rem",
                      }}
                    >
                      Download on Android
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      size="large"
                      startIcon={<AppleIcon />}
                      onClick={handleJoinWaitlist}
                      sx={{
                        bgcolor: "#1D1B20",
                        borderColor: "#1D1B20",
                        color: "white",
                        "&:hover": {
                          bgcolor: "#2D2B30",
                          borderColor: "#2D2B30",
                        },
                        borderRadius: 4,
                        px: 4,
                        py: 1.5,
                        textTransform: "none",
                        fontSize: "1rem",
                      }}
                    >
                      Join iOS Waitlist
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </section>
        </article>
      </main>

      <Container
        component="footer"
        maxWidth={false}
        sx={{ bgcolor: "primary.main", color: "primary.contrastText", py: 8 }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" component="div">
                Goodnewzz
              </Typography>
              <Typography variant="body2">
                Empowering expectant mothers with AI-powered insights and
                personalized care.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Quick Links
              </Typography>
              <Typography variant="body2">
                <Button color="inherit" href="#">
                  Home
                </Button>
              </Typography>
              <Typography variant="body2">
                <Button color="inherit" href="#features">
                  Features
                </Button>
              </Typography>
              <Typography variant="body2">
                <Button color="inherit" href="#waitlist">
                  Join Waitlist
                </Button>
              </Typography>
              <Typography variant="body2">
                <Button color="inherit" href="/privacy-policy">
                  Privacy Policy
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Contact Us
              </Typography>
              <Typography variant="body2">
                Email: support@goodnewzz.app
              </Typography>
              <Typography variant="body2">Phone: +919870164615</Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" align="center" sx={{ mt: 4 }}>
            © 2025 Goodnewzz. All rights reserved.
          </Typography>
        </Container>
      </Container>
    </>
  );
};

export default LandingPage;
